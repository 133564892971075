import { PageContent } from '../styled/PageContent'
import { PageSection } from '../styled/PageSection'
import {
  PageTitleSubText,
  PageTitleText,
  PageTitleWrapper,
  TopSection,
} from '../styled/PageTitle'
import AppDownloadSection from '../components/AppDownloadSection'
import HowItWorksBanner from '../components/HowItWorksBanner'
import { LargeText } from '../styled/LargeText'
import { SmallDescription } from '../styled/Text'
import FeaturesGrid from '../components/FeatureGridComponent'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import AppDownloadCTA from '../components/AppDownloadCTA'

const AppDownloadButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '2em',
  [theme.breakpoints.down('sm')]: {
    gap: '1em',
    flexDirection: 'column',
  },
}))

const Home = () => {
  return (
    <PageContent>
      <TopSection>
        <PageTitleWrapper>
          <PageTitleText>NAVIGATE THE CLUTTER</PageTitleText>
          <PageTitleSubText>with Ease</PageTitleSubText>
        </PageTitleWrapper>
        <AppDownloadButtons>
          <a
            href="https://apps.apple.com/us/app/lastseen-app/id6477953801"
            target="_blank"
            rel="noreferrer"
          >
            <Box
              component="img"
              src={process.env.PUBLIC_URL + '/images/app_store_badge.png'}
              alt="Download on the App Store"
              sx={{ width: 'auto', maxWidth: 200 }}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.lastseen"
            target="_blank"
            rel="noreferrer"
          >
            <Box
              component="img"
              src={process.env.PUBLIC_URL + '/images/google_play_color.png'}
              alt="Get it on Google Play"
              sx={{ width: 'auto', maxWidth: 200 }}
            />
          </a>
        </AppDownloadButtons>
      </TopSection>
      <PageSection>
        <SmallDescription>
          Do you recognize this scenario all too well? You're in the midst of
          accomplishing a task and suddenly, you realize you require a specific
          item to complete it. Although this isn't an object you use regularly,
          you're confident it's within your possession. However, the item's
          location remains elusive, and the time you spend searching for it
          surpasses its current value. Consequently, you find yourself resorting
          to a less-than-ideal substitute, contemplating a new purchase, or
          perhaps even abandoning the task altogether. If this sounds familiar,
          our app is precisely what you need. By using our app, you'll not only
          establish a reliable connection to your item's location but also
          enhance your ability to recall where things are placed.
        </SmallDescription>
        <HowItWorksBanner />
        <FeaturesGrid />
        <AppDownloadCTA />
      </PageSection>
    </PageContent>
  )
}

export default Home
